













































import ProjectValuationMarket from "@/components/project-valuation-market/ProjectValuationMarket.vue";
import { Component } from "vue-property-decorator";
import ResizableSidebarLayout from "@/layouts/ResizableSidebarLayout.vue";
import BuyerAnalyticsForm from "@/components/buyer-analytics/BuyerAnalyticsForm.vue";
import BuyerAnalyticsDataTable from "@/components/buyer-analytics/BuyerAnalyticsDataTable.vue";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";
import Contact from "@/entity/Contact";
import { TableQuery } from "@/components/data-table/types";
import BuyerAnalyticsService from "@/services/BuyerAnalyticsService";
import { BuyerValuationFilterInput } from "@/entity/Analytics";
import { Mixins } from "vue-property-decorator";
import DialogMixin from "@/mixins/DialogMixin";
import * as XLSX from "xlsx-js-style";
import ExportProjectBuyers from "@/components/project-buyers/ExportProjectBuyers.vue";
import ProjectValuationBuyerTypeTiles from "@/components/project-valuation-buyers/ProjectValuationBuyerTypeTiles.vue";
import BuyerAnalyticsViewSwitch from "@/components/buyer-analytics/BuyerAnalyticsViewSwitch.vue";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";
import { BuyerStatusesWithCounters } from "@/entity/ProjectBuyer";
import { BuyerQuality } from "@/entity/Contact";

@Component({
  components: {
    ProjectValuationBuyerTypeTiles,
    ExportProjectBuyers,
    ResizableSidebarLayout,
    ProjectValuationMarket,
    BuyerAnalyticsForm,
    BuyerAnalyticsDataTable,
    BuyerAnalyticsViewSwitch,
  },
})
export default class BuyerAnalyticsView extends Mixins(DialogMixin) {
  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];

  selectedContacts: Contact[] = [];
  deletedContacts: number[] = [];
  contacts: Contact[] = [];
  totalItems = 0;
  isSubmitting = false;
  tableQuery!: TableQuery;
  filterInput: BuyerValuationFilterInput = {};
  showStatistics = false;
  buyerTypesStats!: ProjectValuationBuyerChart[];

  handleSubmit(filterInput: BuyerValuationFilterInput) {
    this.filterInput = filterInput;
    this.deletedContacts = [];
    this.filterBuyers();
  }

  async filterBuyers(): Promise<void> {
    try {
      this.isSubmitting = true;

      const [{ content, totalItems }, stats] = await Promise.all([
        BuyerAnalyticsService.search({
          ...this.tableQuery,
          filterBy: {
            ...this.tableQuery.filterBy,
            ...this.filterInput,
            excludedContacts: this.deletedContacts,
          },
        }),
        BuyerAnalyticsService.stats({
          ...this.tableQuery,
          filterBy: {
            ...this.tableQuery.filterBy,
            ...this.filterInput,
            excludedContacts: this.deletedContacts,
          },
        }),
      ]);
      this.contacts = content;
      this.totalItems = totalItems;
      this.selectedContacts = [];
      this.buyerTypesStats = stats;
    } finally {
      this.isSubmitting = false;
    }
  }

  removeFromSelected(contactIds: number[]) {
    this.selectedContacts = this.selectedContacts.filter(
      (contact) => !contactIds.includes(contact.id as number)
    );
  }

  async deleteContacts(contactIds: number[]) {
    const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));

    if (!didConfirm) {
      return;
    }

    this.deletedContacts = [...this.deletedContacts, ...contactIds];
  }

  async handleDeleteContacts(contactIds: number[]) {
    await this.deleteContacts(contactIds);
    await this.filterBuyers();
    this.removeFromSelected(contactIds);
  }

  onOptionsChange(tableQuery: TableQuery) {
    this.tableQuery = tableQuery;
    this.filterBuyers();
  }

  openExportDialog(): void {
    this.openDialog("export");
  }

  async handleExport(filename: string): Promise<any> {
    const response = await BuyerAnalyticsService.search({
      ...this.tableQuery,
      filterBy: {
        ...this.tableQuery.filterBy,
        ...this.filterInput,
        excludedContacts: this.deletedContacts,
      },
      page: 1,
      itemsPerPage: 9999999,
    });
    const finalData = response.content.map((contact) => {
      const mappedContact: { [x: string]: any } = {
        [this.$tc("firstName")]: contact?.lastName,
        [this.$tc("name")]: contact?.firstName,
        [this.$tc("email")]: contact?.email,
        [this.$tc("companyName")]: contact.company?.name,
        [this.$tc("phoneMobile")]: contact?.phoneMobile,
        [this.$tc("buyerType")]: contact?.buyerType?.name,
      };

      BuyerStatusesWithCounters.forEach((status) => {
        mappedContact[this.$tc(status)] = {
          v: contact?.buyerAnalyticsStats?.buyerQuality[
            status.split(".")[1] as keyof BuyerQuality
          ],
          z: "0",
          s: {
            alignment: { horizontal: "right" },
          },
        };
      });

      return mappedContact;
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(finalData);

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      this.$tc("buyerAnalysis", 1)
    );

    XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });
    this.closeDialog();
  }
}
