































import { Component } from "vue-property-decorator";
import Project from "@/entity/Project";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";
import { DEFAULT_MONEY_RANGE_VALUES } from "@/constants";
import { Mixins } from "vue-property-decorator";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import BuyerAnalyticsFormFilter from "@/components/buyer-analytics/BuyerAnalyticsFormFilter.vue";
import ProjectValuationMarketService from "@/services/projectValuation/ProjectValuationMarketService";
import { BuyerValuationFilterInput } from "@/entity/Analytics";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    BuyerAnalyticsFormFilter,
  },
})
export default class BuyerAnalyticsForm extends Mixins(
  TranslatedEnumListsMixin
) {
  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];
  @State("industries", { namespace: "selectOptions" })
  industries!: ReferenceItem[];
  @State("subIndustries", { namespace: "selectOptions" })
  subIndustries!: ReferenceItem[];
  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];

  @Prop({ required: true, type: Boolean, default: false })
  isSubmitting!: boolean;

  steps = DEFAULT_MONEY_RANGE_VALUES;

  isLoadingProjectOptions = false;
  projects: Project[] = [];

  selectedIndustries: number[] = [];
  selectedSubIndustries: number[] = [];
  selectedRegions: number[] = [];
  selectedRange: [number, number] = [0, DEFAULT_MONEY_RANGE_VALUES.length - 1];
  selectedBuyerType: number[] = [];
  selectedBuyerStatus: string[] = [];
  selectedProjects: number[] = [];

  get filterInput(): BuyerValuationFilterInput {
    return {
      salesPricePublishedMin: this.steps[this.selectedRange[0]],
      salesPricePublishedMax: this.steps[this.selectedRange[1]],
      branchIds: this.selectedIndustries,
      subbranchIds: this.selectedSubIndustries,
      regionIds: this.selectedRegions,
      buyerType: this.selectedBuyerType,
      status: this.selectedBuyerStatus,
      projectIds: this.selectedProjects,
    };
  }

  async handleSubmit(): Promise<void> {
    await this.$emit("submit", this.filterInput);
  }

  async fetchProjectOptions(): Promise<void> {
    try {
      this.isLoadingProjectOptions = true;
      this.projects =
        await ProjectValuationMarketService.projectsForValuation();
    } finally {
      this.isLoadingProjectOptions = false;
    }
  }

  created() {
    this.fetchProjectOptions();
  }
}
